import React from 'react'
import PageCustomers from '../components/pages/Customers/Customers'
import Layout from '../Layouts/en'

const customersEn = ({ location }) => {
  return (
    <Layout location={location}>
      <PageCustomers />
    </Layout>
  )
}

export default customersEn